import {
  Box,
  Button,
  Divider,
  Flex,
  Link,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { appStoreUrl, playStoreUrl } from '@src/constants';
import { logFacebook } from '@src/facebookLogging';
import { useLandingStoreUrls } from '@src/hooks';
import { AppStore as AppStoreIcon, PlayStore } from '@src/icons';
import { determineMobileOperatingSystem } from '@src/utils';
import type { FC, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const mobileOperatingSystem = determineMobileOperatingSystem();

const generateOnClick = (url: string) => (event: MouseEvent) => {
  logFacebook('StoreLinkClicked', true);
  setTimeout(() => {
    window.location.href = url;
  }, 250);

  if (event.metaKey || event.ctrlKey) {
    return;
  }

  event.preventDefault();
};

const AppStoreLinks: FC<{
  variant: string;
  showBrowserLink?: boolean;
  showAlsoAvailableText?: boolean;
}> = ({ variant, showBrowserLink, showAlsoAvailableText }) => {
  const landingStoreUrls = useLandingStoreUrls();
  const buttons = useBreakpointValue(
    {
      base: (
        <>
          <Stack direction="column" spacing="deci">
            {[
              ...(mobileOperatingSystem !== 'android'
                ? [
                    {
                      key: 'appStore',
                      href: landingStoreUrls.appStoreUrl,
                      children: 'Download on the App Store',
                      leftIcon: <AppStoreIcon boxSize="23px" />,
                    },
                  ]
                : []),
              ...(mobileOperatingSystem !== 'ios'
                ? [
                    {
                      key: 'playStore',
                      href: landingStoreUrls.playStoreUrl,
                      children: 'Get it on Google Play',
                      leftIcon: <PlayStore boxSize="23px" />,
                    },
                  ]
                : []),
            ].map(({ key, href, ...button }) => (
              <Button
                key={key}
                as="a"
                variant={variant}
                width="100%"
                size="lg"
                bgColor={variant === 'solidInverted' ? 'white' : undefined}
                onClick={generateOnClick(href)}
                {...button}
              />
            ))}
          </Stack>

          {showBrowserLink && (
            <Link
              as={RouterLink}
              width="100%"
              padding="regular"
              textStyle="popPuffin"
              display="block"
              textAlign="center"
              to="/sign-in?getStarted=true"
              color={variant === 'solidInverted' ? 'white' : undefined}
            >
              Or get started in the browser
            </Link>
          )}
        </>
      ),
      lg: (
        <Box>
          <Button
            as={RouterLink}
            width="100%"
            size="lg"
            to="/sign-in?getStarted=true"
            variant={variant}
          >
            Start your free trial
          </Button>
          {showAlsoAvailableText && (
            <Flex alignItems="center" gap="regular" my="regular">
              <Divider flex={1} />
              <Text textStyle="popPuffin" color="gray.600">
                Also available on mobile
              </Text>
              <Divider flex={1} />
            </Flex>
          )}
          <Flex gap="deci" mt="regular">
            {[
              {
                leftIcon: <AppStoreIcon boxSize="22px" />,
                href: appStoreUrl,
                children: 'App Store',
              },
              {
                leftIcon: <PlayStore boxSize="22px" />,
                href: playStoreUrl,
                children: 'Google Play',
              },
            ].map((button) => (
              <Button
                key={button.href}
                as="a"
                variant="outline"
                borderColor={variant === 'solidInverted' ? 'white' : 'gray.100'}
                color={variant === 'solidInverted' ? 'white' : undefined}
                borderWidth="1px"
                width="100%"
                flex={1}
                onClick={generateOnClick(button.href)}
                {...button}
              />
            ))}
          </Flex>
        </Box>
      ),
    },
    { ssr: false },
  );

  return <>{buttons}</>;
};

export default AppStoreLinks;
