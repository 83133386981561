import {
  DarkMode,
  type DarkModeListenerData,
} from '@aparajita/capacitor-dark-mode';
import { App } from '@capacitor/app';
import { Capacitor, type PermissionState } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { StatusBar, Style } from '@capacitor/status-bar';
import { NavigationBar } from '@capgo/capacitor-navigation-bar';
import {
  useDisclosure as useChakraDisclosure,
  useColorMode,
  useToast,
} from '@chakra-ui/react';
import { useRollbar } from '@rollbar/react';
import { getMe, updateMe } from '@src/api';
import { addBackListener, removeBackListener } from '@src/backListener';
import { appStoreUrl, playStoreUrl } from '@src/constants';
import { AppStore } from '@src/stores';
import {
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AFConstants, AppsFlyer } from 'appsflyer-capacitor-plugin';
import { usePostHog } from 'posthog-js/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { deleteEntry, getEntry } from './api';
import { colors } from './theme';
import { checkPushNotificationPermissions } from './utils';

export const useDisclosure = ({
  name,
  onClose,
}: {
  name: string;
  onClose?: () => void;
}) => {
  const posthog = usePostHog();
  const disclosure = useChakraDisclosure({
    onOpen: () => {
      posthog?.capture(`${name} Disclosure Opened`);
      addBackListener(disclosure.onClose);
    },
    onClose: () => {
      posthog?.capture(`${name} Disclosure Closed`);
      removeBackListener(disclosure.onClose);
      if (onClose) {
        onClose();
      }
    },
  });

  useEffect(() => {
    return () => {
      removeBackListener(disclosure.onClose);
    };
  }, [disclosure.onClose]);

  return disclosure;
};

/**
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return;
  }, [delay]);
};

/**
 * Complexity is to prevent the issue where the user makes a change and quickly navigates away before the mutation completes. Then the decision to delete is out of date.
 */
export const useDeleteEmptyEntry = () => {
  const [deletedEntryIds, setDeletedEntryIds] = useState<
    Record<string, boolean>
  >({});
  const [[previousPathname, currentPathname], setPathnames] = useState([
    '',
    '',
  ]);
  const queryClient = useQueryClient();
  const location = useLocation();
  const pathname = location.pathname;
  const toast = useToast();
  const mutationCount = useIsMutating({
    mutationKey: ['entries'],
  });

  const previousMatch = matchPath(
    '/entries/:entryId/taskTypes/:taskType',
    previousPathname,
  );

  const match = matchPath('/entries/:entryId/taskTypes/:taskType', pathname);
  const isCurrentPageLearn = !!matchPath('/learn/:articleId', pathname);

  const previousEntryId = previousMatch?.params.entryId;
  const entryId = match?.params.entryId;

  const isEntryQueryEnabled =
    !!previousEntryId && !deletedEntryIds[previousEntryId];

  const entryQuery = useQuery({
    queryKey: ['entries', previousEntryId],
    enabled: isEntryQueryEnabled,
    queryFn: () => {
      if (!previousEntryId) throw new Error('Missing entry ID');
      return getEntry(previousEntryId);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id: string) => {
      return deleteEntry(id, true);
    },
    onSuccess: () => {
      toast({
        title: 'Empty entry deleted',
        duration: 2000,
        position: 'top',
      });
      queryClient.invalidateQueries({
        queryKey: ['entries'],
      });
    },
  });

  // When the user navigates away from the entries page, it will flush any pending mutations.
  // But isLoading state of mutation won’t necessarily propagate fast enough. So wait a bit for that to happen.
  const conditionallyDeletePreviousEntry = useDebouncedCallback(() => {
    if (
      previousEntryId &&
      previousEntryId !== entryId &&
      !deleteMutation.isPending &&
      mutationCount === 0 &&
      !entryQuery.isFetching &&
      isEntryQueryEnabled &&
      entryQuery.data?.isEmpty &&
      !isCurrentPageLearn
    ) {
      setDeletedEntryIds({ ...deletedEntryIds, [previousEntryId]: true });
      deleteMutation.mutate(previousEntryId);
    }
  }, 50);

  // biome-ignore lint/correctness/useExhaustiveDependencies: necessary
  useEffect(() => {
    if (pathname !== currentPathname) {
      setPathnames([currentPathname, pathname]);
    }
  }, [pathname, currentPathname, previousPathname]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: necessary
  useEffect(() => {
    if (previousPathname !== pathname) {
      conditionallyDeletePreviousEntry();
    }
  }, [
    conditionallyDeletePreviousEntry,
    previousPathname,
    pathname,
    previousEntryId,
    entryQuery,
    mutationCount,
    deleteMutation,
    entryId,
    deletedEntryIds,
  ]);
};

export const useInitAppsFlyer = (canMakeRequests: boolean) => {
  const posthog = usePostHog();
  const rollbar = useRollbar();
  const appsFlyerIsSet = useRef(false);
  const queryClient = useQueryClient();
  const meQuery = useQuery({
    queryKey: ['me'],
    queryFn: getMe,
    enabled: canMakeRequests,
  });
  const meMutation = useMutation({
    mutationFn: updateMe,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['me'],
      }),
  });

  useEffect(() => {
    if (
      !appsFlyerIsSet.current &&
      Capacitor.isPluginAvailable('AppsFlyerPlugin') &&
      meQuery.data
    ) {
      appsFlyerIsSet.current = true;

      // Listener must be called before init
      // https://github.com/AppsFlyerSDK/appsflyer-capacitor-plugin/blob/main/docs/Guides.md#-3-unified-deep-linking
      AppsFlyer.addListener(AFConstants.UDL_CALLBACK, (res) => {
        // This will hit, not only for Onelink, but for universal links in general, such as for sign in.
        if (res.status === 'FOUND') {
          // See valid deep link parameters: https://github.com/AppsFlyerSDK/appsflyer-capacitor-plugin/blob/main/docs/Guides.md#%EF%B8%8Fimportant
          // "UDL conforms to the iOS 14.5+ privacy standards and only returns parameters relevant to deep linking and deferred deep linking: deep_link_value and deep_link_sub1. Attribution parameters (such as media_source, campaign, af_sub1-5, etc.), return null and can’t be used for deep linking purposes."
          const referrerId =
            res.deepLink.deep_link_sub1 || res.deepLink.deep_link_value; // TODO: deep_link_value is deprecated for referrerId. Remove at some point.
          if (referrerId) {
            meMutation.mutate({
              referrerId,
            });
            posthog?.capture('ReferrerId saved', { referrerId });
          }
        } else if (res.status === 'ERROR') {
          rollbar.error(res.error);
        }
      });

      AppsFlyer.initSDK({
        appID: '1613810335',
        devKey: 'FuQfm45pRmwYDAHgqiRnr4',
        isDebug: import.meta.env.DEV,
        registerOnDeepLink: true,
      });
    }
  }, [meMutation, meQuery.data, rollbar, posthog]);
};

export const useSetColorMode = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (mode: 'light' | 'dark') => {
    if (Capacitor.isPluginAvailable('StatusBar')) {
      StatusBar.setStyle({
        style: mode === 'light' ? Style.Light : Style.Dark,
      });
      StatusBar.setBackgroundColor({
        color: mode === 'light' ? '#FFFFFF' : colors.gray[900],
      });
    }
    if (
      Capacitor.isPluginAvailable('NavigationBar') &&
      Capacitor.getPlatform() === 'android' // isPluginAvailable check doesn’t work.
    ) {
      NavigationBar.setNavigationBarColor({
        color: mode === 'light' ? colors.white : colors.gray[900],
      });
    }

    if (colorMode !== mode) {
      toggleColorMode();
    }
  };
};

export const useColorModeManage = (canMakeRequests: boolean) => {
  const setColorMode = useSetColorMode();
  const meQuery = useQuery({
    queryKey: ['me'],
    queryFn: getMe,
    enabled: canMakeRequests,
  });

  const selectedColorMode = meQuery.data?.user.colorMode;

  const handleDarkMode = useCallback(
    ({ dark }: DarkModeListenerData) => {
      setColorMode(dark ? 'dark' : 'light');
    },
    [setColorMode],
  );

  useEffect(() => {
    if (!selectedColorMode) return;

    const mapping = {
      Light: 'light',
      Dark: 'dark',
    } as const;

    if (selectedColorMode === 'System') {
      DarkMode.isDarkMode().then((result) => {
        handleDarkMode(result);
      });
      const listenerPromise = DarkMode.addAppearanceListener(handleDarkMode);
      return () => {
        listenerPromise.then(({ remove }) => {
          remove();
        });
      };
    }

    setColorMode(mapping[selectedColorMode]);
    return;
  }, [handleDarkMode, selectedColorMode, setColorMode]);
};

export const usePushNotificationPermission = () => {
  const [permission, setPermission] = useState<PermissionState>();
  const [isLoading, setIsLoading] = useState(true);

  const checkAndSet = async () => {
    try {
      const result = await checkPushNotificationPermissions();
      setPermission(result);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Just get Biome migration working
  useEffect(() => {
    checkAndSet();

    const listener = App.addListener('appStateChange', checkAndSet);

    return () => {
      listener.then(({ remove }) => {
        remove();
      });
    };
  }, []);

  return { permission, isLoading };
};

export const useCanShare = () => {
  const [canShare, setCanShare] = useState<boolean>();

  useEffect(() => {
    (async () => {
      const { value } = await Share.canShare();
      setCanShare(value);
    })();
  }, []);

  return canShare;
};

export const useLandingStoreUrls = () => {
  const referrerId = AppStore.useState((s) => s.referrerId);
  const onelinkUrl = `https://unstuck.onelink.me/zjZf/wglkyvts?deep_link_sub1=${referrerId}`;

  return {
    appStoreUrl: referrerId ? onelinkUrl : appStoreUrl,
    playStoreUrl: referrerId ? onelinkUrl : playStoreUrl,
  };
};

export const useMatchTeam = (override?: boolean) => {
  const meQuery = useQuery({
    queryKey: ['me'],
    queryFn: getMe,
  });

  if (override != null) return override;

  const emails = ['mariahagsten2@gmail.com', 'stephaniehtyu@gmail.com'];

  return (
    import.meta.env.DEV ||
    emails.includes(meQuery.data?.identityUser?.email ?? '')
  );
};
