import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Image,
  Link,
  Spacer,
  Stack,
  StackDivider,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import logoImage from '@src/assets/logo.svg';
import { useLandingStoreUrls } from '@src/hooks';
import { ChevronLeft } from '@src/icons';
import { determineMobileOperatingSystem } from '@src/utils';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AppStoreLinks from './AppStoreLinks';

const links = [
  { to: '/learn', text: 'Learn' },
  { to: '/about', text: 'About' },
  { to: '/sign-in', text: 'Sign in' },
];

const logo = (
  <Image
    src={logoImage}
    height={{ base: '28px', md: '30px' }}
    alt="Unstuck home"
  />
);

const mobileOperatingSystem = determineMobileOperatingSystem();

const NavBar: FC = () => {
  const landingStoreUrls = useLandingStoreUrls();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const getStartedUrlMapping = {
    ios: landingStoreUrls.appStoreUrl,
    android: landingStoreUrls.playStoreUrl,
    other: '/sign-in?getStarted=true',
  };
  const getStartedButton = (
    <Button
      as={RouterLink}
      to={getStartedUrlMapping[mobileOperatingSystem]}
      variant="gradient"
      color="white"
      textStyle="popPuffin"
      size="sm"
    >
      Get started
    </Button>
  );
  const actions = useBreakpointValue(
    {
      base: (
        <>
          {getStartedButton}
          <IconButton
            aria-label="Open menu"
            icon={<HamburgerIcon width="22px" height="22px" />}
            onClick={onOpen}
            color="gray.400"
            variant="link"
            minWidth="auto"
            p="deci"
          />
        </>
      ),
      md: (
        <>
          {links.map(({ to, text }) => (
            <Link
              key={to}
              as={RouterLink}
              to={to}
              textStyle="popPuffin"
              color="blue.500"
            >
              {text}
            </Link>
          ))}
          {getStartedButton}
        </>
      ),
    },
    { ssr: false },
  );

  return (
    <Flex
      as="nav"
      position="sticky"
      top={0}
      backgroundColor="neutral.bg.0"
      alignItems="center"
      justifyContent="space-between"
      pl={{ base: 'regular', md: 'mega' }}
      pr={{ md: 'mega' }}
      gap={{ base: 'milli', md: 'hecto' }}
      mt={{ base: 'deci', md: 'deka' }}
      zIndex={1}
    >
      <Link as={RouterLink} to="/" py="deci" display="inline-block">
        {logo}
      </Link>
      <Spacer />
      {actions}

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100svh"
            width="100%"
            position="absolute"
            p="regular"
          >
            <Box>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb="deka"
              >
                {logo}
                <DrawerCloseButton position="static" />
              </Flex>
              <Stack
                direction="column"
                divider={<StackDivider borderColor="gray.100" />}
                borderY="1px"
                borderColor="gray.100"
                spacing="deci"
                py="deci"
              >
                {links.map(({ to, text }) => (
                  <Link
                    key={to}
                    as={RouterLink}
                    to={to}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    textStyle="popPuffin"
                    px="deci"
                    color="gray.900"
                  >
                    <Box as="span">{text}</Box>
                    <ChevronLeft
                      boxSize="13px"
                      transform="rotate(180deg)"
                      color="blue.500"
                      aria-hidden
                    />
                  </Link>
                ))}
              </Stack>
            </Box>
            <Box>
              <AppStoreLinks variant="outline" showBrowserLink />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default NavBar;
