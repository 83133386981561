import { Box, Button, Heading, Link } from '@chakra-ui/react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const NativeOpenInApp: FC = () => {
  return (
    <Box position="absolute" height="100%" width="100%">
      <Heading textStyle="popHeron">Complete sign in</Heading>

      <Button
        my="regular"
        as="a"
        href={`https://unstuck.onrender.com${location.pathname}${location.search}${location.hash}`}
        width="100%"
        size="lg"
      >
        Open in the app
      </Button>
      <Box textAlign="center">
        <Link
          as={RouterLink}
          to={`/complete-email-sign-in${location.search}${location.hash}`}
        >
          Or, sign in on this browser instead.
        </Link>
      </Box>
    </Box>
  );
};

export default NativeOpenInApp;
