import { lazy } from 'react';

/**
 * Split out view importing.
 * Include mission critical landing page and sign in pages in main bundle.
 * For rest of pages, pre-fetch.
 *
 * Pre-fetching is required to avoid a bug!
 * New app deploys will change the path of the static assets (index123.js -> index456.js). Without pre-fetching, users that have the old version of the app request index123.js and hit an error.
 *
 * Delay prefetching by a few seconds. It reduces initial JS execution and network load — better for initial landing page loading and Lighthouse score.
 */

export { default as CompleteEmailSignIn } from './views/CompleteEmailSignIn';
export { default as ErrorView } from './views/Error';
export { default as Landing } from './views/Landing';

const importers = {
  dashboard: () => import('./views/Dashboard'),
  onboarding: () => import('./views/Onboarding'),
  contact: () => import('./views/Contact'),
  termsOfService: () => import('./views/TermsOfService'),
  privacyPolicy: () => import('./views/PrivacyPolicy'),
  howToAccount: () => import('./views/HowToAccount'),
  phqAdsQuestionnaire: () => import('./views/PhqAdsQuestionnaire'),
  assessmentResults: () => import('./views/AssessmentResults'),
  signIn: () => import('./views/SignIn'),
  learnList: () => import('./views/LearnList'),
  learnItem: () => import('./views/LearnItem'),
  entryView: () => import('./views/EntryView'),
  summary: () => import('./views/EntryView/Summary'),
  taskView: () => import('./views/EntryView/TaskView'),
  exposuresList: () => import('./views/EntryView/ExposuresListView'),
  entrySelector: () => import('./views/EntrySelector'),
  profile: () => import('./views/Profile'),
  appearance: () => import('./views/Profile/Appearance'),
  entries: () => import('./views/Profile/Entries'),
  goals: () => import('./views/Profile/Goals'),
  notifications: () => import('./views/Profile/Notifications'),
  pinSetup: () => import('./views/Profile/PinSetup'),
  insights: () => import('./views/Insights'),
  thinkingTrapsView: () => import('./views/Insights/ThinkingTrapsView'),
  journalCalendarView: () => import('./views/Insights/JournalCalendarView'),
  subscriptions: () => import('./views/Subscriptions'),
  freeRequest: () => import('./views/Subscriptions/FreeRequest'),
  about: () => import('./views/About'),
  exposures: () => import('./views/Exposures'),
};

export const Dashboard = lazy(importers.dashboard);
export const Onboarding = lazy(importers.onboarding);
export const Contact = lazy(importers.contact);
export const TermsOfService = lazy(importers.termsOfService);
export const PrivacyPolicy = lazy(importers.privacyPolicy);
export const HowToAccount = lazy(importers.howToAccount);
export const PhqAdsQuestionnaire = lazy(importers.phqAdsQuestionnaire);
export const AssessmentResults = lazy(importers.assessmentResults);
export const SignIn = lazy(importers.signIn);
export const LearnList = lazy(importers.learnList);
export const LearnItem = lazy(importers.learnItem);
export const EntryView = lazy(importers.entryView);
export const Summary = lazy(importers.summary);
export const TaskView = lazy(importers.taskView);
export const ExposuresList = lazy(importers.exposuresList);
export const EntrySelector = lazy(importers.entrySelector);
export const Profile = lazy(importers.profile);
export const Appearance = lazy(importers.appearance);
export const Entries = lazy(importers.entries);
export const Goals = lazy(importers.goals);
export const Notifications = lazy(importers.notifications);
export const PinSetup = lazy(importers.pinSetup);
export const Insights = lazy(importers.insights);
export const ThinkingTrapsView = lazy(importers.thinkingTrapsView);
export const JournalCalendarView = lazy(importers.journalCalendarView);
export const Subscriptions = lazy(importers.subscriptions);
export const FreeRequest = lazy(importers.freeRequest);
export const About = lazy(importers.about);
export const Exposures = lazy(importers.exposures);

setTimeout(() => {
  Object.values(importers).forEach((importer) => importer());
}, 4000);
